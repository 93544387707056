@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900&display=swap');

body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body > #root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body > #root > footer {
  margin-top: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.is-family-mont-serrat {
  font-family: 'Montserrat';
}

.mt-2r {
  margin-top: 2rem;
}

.is-size-s {
  font-size: 0.875rem;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}
